import React from "react"
import Layout from "../layout"

import "../css/contact.css"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

export default function Contact() {
  return (
    <Layout>
      <form className="Contact">
        <h2>Your Details</h2>

        <fieldset className="Contact__info">
          <input type="text" name="name" placeholder="Name*" required />
          <input
            type="email"
            autoCapitalize="off"
            autoCorrect="off"
            name="email"
            placeholder="Your Email*"
            required
          />
          <input type="tel" name="phone" placeholder="Phone Number" />
        </fieldset>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <fieldset className="Contact__event">
            <h2>Your Event</h2>

            <label>
              Type of Event
              <br />
              <select name="type">
                <option value="" disabled selected>
                  Select One
                </option>
                {[
                  "Corporate",
                  "Product Launch/Media",
                  "Wedding",
                  "Bridal Shower",
                  "Social (Mitzvah, Grad, Prom, Birthday)",
                  "Holiday Party",
                  "Not For Profit/Charity",
                  "Other",
                ].map((event, i) => (
                  <option value={event}>{event}</option>
                ))}
              </select>
            </label>

            <label>
              Preferred Dates
              <input type="text" name="dates" placeholder="Type Here" />
            </label>

            <label>
              Approximate Event Time
              <input type="text" name="time" placeholder="Type Here" />
            </label>

            <label>
              Number of Guests Expected
              <input type="text" name="guests" placeholder="Type Here" />
            </label>

            <label>
              How did you hear about us?
              <input type="text" name="hear" placeholder="Type Here" />
            </label>
          </fieldset>
        </div>

        <fieldset className="Contact__marketing">
          <label>
            Tell us more about your event:
            <textarea rows="10"></textarea>
          </label>

          <div>
            <label>
              <input type="checkbox" value="mailchimp" />
              Add me to your Newsletter
            </label>
          </div>
        </fieldset>
        <div class="CTA">
          <button className="Button" type="submit">
            Submit
          </button>
        </div>
      </form>
    </Layout>
  )
}
