import React from "react"

import "../css/footer.css"

import Social from "./Social"
import DarrSVG from "../svg/d-arr.svg"

export default function Footer(props) {
  return (
    <div>
      <span class="Darr">
        <DarrSVG />
      </span>
      <p className="Address Footer__contact">461 King West, Toronto</p>

      <footer className="Footer">
        <div>
          <h2>Keep in Touch</h2>
          <p className="Footer__contact">
            Subscribe to our list for special offers and happenings.
          </p>
          <form
            action="https://2ndfloorevents.us14.list-manage.com/subscribe/post"
            method="POST"
          >
            <input type="hidden" name="u" value="bc3e56ab86a16c1ad19a02736" />
            <input type="hidden" name="id" value="212799e8b0" />
            <input
              type="email"
              autoCapitalize="off"
              autoCorrect="off"
              name="MERGE0"
              id="MERGE0"
              placeholder="Your Email Address"
              required
            />
            <button type="submit" className="Button">
              Submit
            </button>
          </form>
          <p className="Footer__copyright">
            Copyright {new Date().getFullYear()} ©
            Second&nbsp;Floor&nbsp;Events. All rights reserved. Website by
            Saevil Row.{" "}
          </p>
        </div>
        <div>
          <Social />
          <p className="Footer__contact">
            Contact Us: <a href="tel:+1-416-263-0122">(416) 263-0122</a> —{" "}
            <a href="mailto:info@2ndfloorevents.com">info@2ndfloorevents.com</a>
          </p>
        </div>
      </footer>
    </div>
  )
}
