import React, { useState } from "react"
import { Link } from "gatsby"

import "modern-normalize/modern-normalize.css"
import "./css/base.css"
import "./css/nav.css"

import Menu from "./components/Menu"
import Footer from "./components/Footer"

import LogoSVG from "./svg/logo-black.svg"
import HamburgerSVG from "./svg/hamburger.svg"

export default function Layout({ children }) {
  const [nav, setNav] = useState(false)
  return (
    <>
      <div className="Nav">
        <Link to="/">
          <LogoSVG />
        </Link>
        <button
          onClick={() => setNav(true)}
          aria-label="Open Navigation"
          className="Nav__hamburger"
        >
          <HamburgerSVG />
        </button>
      </div>
      {nav && <Menu toggleNav={() => setNav(false)} />}
      <main>{children}</main>
      <Footer />
    </>
  )
}
