import React from "react"
import { Link } from "gatsby"

import "../css/menu.css"

import LogoSVG from "../svg/logo-white.svg"
import Nav from "./Nav"

export default function Menu(props) {
  return (
    <div className="Menu">
      <Link to="/">
        <LogoSVG />
      </Link>
      <div>
        <button onClick={props.toggleNav} aria-label="Close Navigation">
          X
        </button>
      </div>
      <Nav toggleNav={props.toggleNav} />
    </div>
  )
}
